import React from "react";

export const Footer = () => {
	return (
		<>
			<div className="footer small navbar fixed-bottom footerBox flex-row d-flex justify-content-center">
				<span className="powered__text"> Powered by EDIPTECH</span>
			</div>
		</>
	);
};
