export const redemptions = [
	{
		id: 1,
		idPuntoVenta: 2,
		clienteRef: "PLDOCS3X",
		descrEstado: "Redención generada correctamente",
		estado: "Activo",
		fecha: "2022-01-21T04:10:55.101Z",
		fechaCreacion: "2022-01-21T04:10:55.101Z",
		fechaModificacion: "2022-01-21T04:10:55.101Z",
		redencion: "NEQ0001",
		referencia: "M46",
		usariosCreacion: "Admin",
		usariosModificacion: "Admin",
		links: {
			empty: true,
		},      
	},
	{
		id: 2,
		idPuntoVenta: 4,
		clienteRef: "PLDOCS3X",
		descrEstado: "Redención generada correctamente",
		estado: "Activo",
		fecha: "2022-01-20T04:10:55.101Z",
		fechaCreacion: "2022-01-20T04:10:55.101Z",
		fechaModificacion: "2022-01-20T04:10:55.101Z",
		redencion: "NEQ0002",
		referencia: "M48",
		usariosCreacion: "Admin",
		usariosModificacion: "Admin",
		links: {
			empty: true,
		},      
	},   
   {
		id: 3,
		idPuntoVenta: 1,
		clienteRef: "PLDOCS3X",
		descrEstado: "Redención revertida correctamente",
		estado: "Inactivo",
		fecha: "2022-01-20T04:10:55.101Z",
		fechaCreacion: "2022-01-20T04:10:55.101Z",
		fechaModificacion: "2022-01-20T04:10:55.101Z",
		redencion: "BON0022",
		referencia: "R23",
		usariosCreacion: "Admin",
		usariosModificacion: "Admin",
		links: {
			empty: true,
		},      
	},   
	{
		id: 4,
		idPuntoVenta: 2,
		clienteRef: "PLDOCS3X",
		descrEstado: "Redención generada correctamente",
		estado: "Activo",
		fecha: "2022-01-20T04:10:55.101Z",
		fechaCreacion: "2022-01-22T04:10:55.101Z",
		fechaModificacion: "2022-01-22T04:10:55.101Z",
		redencion: "NEQ0003",
		referencia: "M50",
		usariosCreacion: "Admin",
		usariosModificacion: "Admin",
		links: {
			empty: true,
		},      
	},   

];
